<template>

    <div class="h-full w-full rounded-lg bg-filters flex flex-col relative" @click="$router.push({ name : 'ProjectsManagement' })">

        <loader :loading="loading" />

        <div v-if="ner" class="h-8 py-2 flex flex-row justify-between items-center px-2">
            <span class="font-semibold text-xs" style="color: #13F2E3">
                Project Management
            </span>
            <i v-if=" ner.objective_percentage > 99&&isNotNaAndTbd(ner.objective_percentage )" class="mdi mdi-arrow-top-right text-success text-aux text-xs
                    cursor-pointer"></i>
            <i v-else-if="isNotNaAndTbd(ner.objective_percentage )" class="mdi mdi-arrow-bottom-left text-danger text-aux text-xs"></i>
        </div>

        <div v-if="ner" class="flex-1 min-h-0 flex flex-col justify-center items-center relative">

            <chart-gauge :data="[ner.objective_percentage]" />

            <div class="absolute flex flex-col justify-center items-center">
                <span class="text-white font-semibold text-xs">
                    {{ ner.ner || 0 | reduceBigNumbers(2) }}  €
                </span>
                <span class="text-3xs"
                        :class="successOrDangerClass(ner.objective_percentage,100)">
                    {{ ner.objective_percentage || 0 | numberFormat(2) }}
                    <span v-if="isNotNaAndTbd(ner.objective_percentage)">%</span>

                </span>
            </div>

        </div>

    </div>

</template>

<script>

    const ChartDoubleGauge = () => import('@/charts/ChartDoubleGauge.vue');
    import { state } from '@/store';

    export default {
        name: "ProjectsManagementHomeMobile",
        components: {
            ChartDoubleGauge
        },
        data() {
            return {
                loading: false,
                sales: {},
                ner: {}
            }
        },
        computed: {
            params() {
                let params = state.globalParams
                if (this.$route.params.catId) {
                    this.loading = false
                    params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
                }
                return params
            },
        },
        watch: {
            params() { this.load() }
        },
        methods: {
            load() {
                this.loading = true;
                if(this.params) {
                    this.axios.get('projects_management/ner', {params: this.params})
                        .then(response => {
                            this.loading = false
                            this.ner = response.data.data
                        })
                }
            }
        },
        mounted(){
            this.load();
        }
    }
</script>